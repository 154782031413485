import { CustomizationBase } from "@/script/logic/customization/customization-base";
import { ISectionInfo } from "@/script/logic/extraction/section-info";
import { NodeAnalyzer } from "@/script/logic/extraction/node-analyzer";

export const isShopify = function () {
	return (window as any).Shopify || !!([...document.head.childNodes].find(x => x.textContent.indexOf('shopify') > 0))
}

const knownSections = new Set(['header', 'footer', 'cookie']);

export class ShopifyCustomization extends CustomizationBase {
	private readonly _sectionSelector = '.shopify-section';
	private readonly _productJsonSelector = '[data-product-json]';

	public isIgnored(node: Node): boolean {
		if (node instanceof Element) {
			return !!node.closest(this._productJsonSelector);
		}
		return false;
	}

	public findSectionForElement(element: Element): ISectionInfo | null {
		const section = element.closest(this._sectionSelector);
		if (section) {

			// Sample sections:
			// shopify-section-sections--15254767730758__header
			// shopify-section-header
			// shopify-section__header

			const sectionName = section.id?.match(/shopify-section.*?([a-z]{3,})$/)[1];
			if (knownSections.has(sectionName?.toLowerCase())) {
				return {
					id: sectionName,
					customLang: NodeAnalyzer.tryGetExplicitlySetLang(section),
				};
			}
		}
		return null;
	}

}